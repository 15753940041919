import React, {useEffect, useRef, useState} from 'react';
import {TabContainer} from "../../../components"
import Level9Coverage from "../components/Level9Coverage.comp";
import Level8Coverage from "../components/Level8Coverage.comp";
import Level7Coverage from "../components/Level7Coverage.comp";
import Level7Alignment from "../components/Level7Alignment.comp";
import Level6Depth from "../components/Level6Depth.comp";
import Level6Breadth from "../components/Level6Breadth.comp";
import Level6Enough from "../components/Level6Enough.comp";
import Level5Analysis from "../components/Level5Analysis.comp";
import {Button, Checkbox, darken, FormControlLabel} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {ANALYSIS_TYPES, ANALYSIS_TYPES_VERBOSE, APP_STATUS, LM_TYPE} from "../../../utils/constants";
import {toastSuccess} from "../../../utils/utils";
import {downloadLearningMessagesExcel} from "../utils/learningMessagesExcel.util";
import {makeStyles} from "@material-ui/core/styles";
import {
    getAppService,
    getLearningMsgsService,
    getLevelMapsService,
    postAnalysisService,
    updateExcludeNonDQCService
} from "../../../services/app.service";
import {
    calculateLevel5Analysis,
    calculateLevel6Breadth,
    calculateLevel6Depth,
    calculateLevel6Enough,
    calculateLevel7Alignment,
    calculateLevel7Coverage,
    calculateLevel8Coverage,
    calculateLevel9Coverage
} from "../../../utils/coverage_calculator";

const useStyles = makeStyles((theme) => ({
    excel_button: {
        backgroundColor: "#006400",
        color: "white",
        '&:hover': {
            backgroundColor: darken("#006400", 0.2),
        }
    },
    red_checkbox: {
        '&.Mui-checked': {
            color: "darkred"
        }
    },
}));
function AnalysisModule(props) {
    const classes = useStyles();

    const {app, application_id, app_status, updateStatus, forwardStep, setLoading, updateApp} = props;
    const [reportGenerated, setReportGenerated] = useState(false);
    const allLearnMsgsRef = useRef([]);
    const [is_exclusive_non_dqc, set_is_exclusive_non_dqc] = useState(app.is_exclusive_non_dqc);
    const [reload, setReload] = useState(false);

    const statusGreaterThanModule = app_status > APP_STATUS.PENDING_ANALYSIS;

    function isRowNA(row) {
        return row.level_9 === "N/A" || row.level_8 === "N/A" || row.level_6 === "N/A" || row.level_5 === "N/A";
    }

    async function fetchLearningMessages() {
        if (allLearnMsgsRef.current.length < 1) {
            try {
                const result = await getLearningMsgsService(application_id);
                if(!result.error) {
                    const {messages = [], threshold} = result.data;
                    allLearnMsgsRef.current = messages.filter((msg) => msg.type === LM_TYPE.LESSON || msg.type === LM_TYPE.INSTRUMENT);
                    allLearnMsgsRef.current = allLearnMsgsRef.current.filter(item =>
                        !isRowNA(item) && !item.is_deleted
                    );
                }
            }
            catch (e) {
                console.error(e);
            }
        }
    }

    async function generateReport() {
        try {
            setLoading(true);
            if (!statusGreaterThanModule) {
                await updateStatus(APP_STATUS.PENDING_REVIEW_REPORT);
                toastSuccess('Success', 'Report Generated');
            }
            setReportGenerated(true);
        } catch (error) {
            console.error("Failed to generate report:", error);
        }
        setLoading(false);
    }

    const fetchLevelMappings = async () => {
        try {
            const result = await getLevelMapsService();
            if (!result.error) {
                return result.data;
            }
        } catch (e) {
            console.error(e);
        }
        return undefined;
    };

    const isDQCNumber = (dqc_no) => {
        const number_part = dqc_no.slice(3);
        return number_part >= '01' && number_part <= '32';
    }


    const reCalculateAnalysis = async () => {
        try {
            setLoading(true);
            await fetchLearningMessages();
            const mappings = await fetchLevelMappings();

            const filteredMessages = is_exclusive_non_dqc
                ? allLearnMsgsRef.current.filter(message =>
                    message.dqc_no.startsWith('DQC') && isDQCNumber(message.dqc_no)
                )
                : allLearnMsgsRef.current;

            const level9_coverage = calculateLevel9Coverage(filteredMessages, mappings);
            const level8_coverage = calculateLevel8Coverage(level9_coverage, mappings);
            const level7_coverage = calculateLevel7Coverage(level8_coverage, mappings);
            const level7_alignment = calculateLevel7Alignment(level7_coverage, level8_coverage, mappings);
            const level6_depth = calculateLevel6Depth(level7_alignment, level8_coverage, mappings);
            const level6_breadth = calculateLevel6Breadth(level6_depth, mappings);
            const level6_enough = calculateLevel6Enough(level8_coverage, mappings);
            const level5_analysis = calculateLevel5Analysis(level6_enough, mappings);

            await postAnalysisService(application_id, ANALYSIS_TYPES.LEVEL_9_COVERAGE, level9_coverage, null);
            await postAnalysisService(application_id, ANALYSIS_TYPES.LEVEL_8_COVERAGE, level8_coverage, null);
            await postAnalysisService(application_id, ANALYSIS_TYPES.LEVEL_7_COVERAGE, level7_coverage, null);
            await postAnalysisService(application_id, ANALYSIS_TYPES.LEVEL_7_ALIGNMENT, level7_alignment, null);
            await postAnalysisService(application_id, ANALYSIS_TYPES.LEVEL_6_DEPTH, level6_depth, null);
            await postAnalysisService(application_id, ANALYSIS_TYPES.LEVEL_6_BREADTH, level6_breadth, null);
            await postAnalysisService(application_id, ANALYSIS_TYPES.LEVEL_6_ENOUGH, level6_enough, null);
            await postAnalysisService(application_id, ANALYSIS_TYPES.LEVEL_5_ANALYSIS, level5_analysis, null);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const fetchUpdatedApplicationInfo = async () => {
        try {
            setLoading(true);
            const result = await getAppService(application_id);
            if (result && !result.error) {
                set_is_exclusive_non_dqc(result.data.is_exclusive_non_dqc);
                updateApp(result.data);
            } else {
                console.log('Error fetching updated app data');
            }
        } catch (error) {
            console.error('Error fetching app data:', error);
        } finally {
            setLoading(false);
        }
    };

    const updateAppAndRecalculateAnalysis = async () => {
        await updateExcludeNonDQCService(application_id, is_exclusive_non_dqc);
        await fetchUpdatedApplicationInfo();
        await reCalculateAnalysis();
        setReload(prev => !prev);
    };

    useEffect(() => {
        (async () => {
            await fetchUpdatedApplicationInfo();
        })();
    }, []);


    useEffect(() => {
        if (reportGenerated) {
            forwardStep();
            setReportGenerated(false);
        }
    }, [reportGenerated, forwardStep]);

    const downloadExcel = async () => {
        await fetchLearningMessages();
        let filteredMessages = null;
        if(is_exclusive_non_dqc) {
            filteredMessages = allLearnMsgsRef.current.filter(message =>
                message.dqc_no.startsWith('DQC') && isDQCNumber(message.dqc_no)
            );
        }
        await downloadLearningMessagesExcel(application_id, filteredMessages ? filteredMessages : allLearnMsgsRef.current);
    };

    const handleCheckboxChange = (e) => {
        set_is_exclusive_non_dqc(e.target.checked);
    };

    return <>
        <Box textAlign={'right'}>
            <FormControlLabel
                control={<Checkbox className={classes.red_checkbox} name={`Checkbox-Exclude-Non-DQC`} checked={is_exclusive_non_dqc} onChange={handleCheckboxChange}/>}
                label={'Exclude Non-DQC DQC#'}
            />
            <Button variant="contained" color="primary" onClick={updateAppAndRecalculateAnalysis}>Recalculate Analysis</Button>
        </Box>
        <TabContainer>
            <Level9Coverage title={ANALYSIS_TYPES_VERBOSE.LEVEL_9_COVERAGE} {...props} reload={reload}/>
            <Level8Coverage title={ANALYSIS_TYPES_VERBOSE.LEVEL_8_COVERAGE} {...props} reload={reload}/>
            <Level7Coverage title={ANALYSIS_TYPES_VERBOSE.LEVEL_7_COVERAGE} {...props} reload={reload}/>
            <Level7Alignment title={ANALYSIS_TYPES_VERBOSE.LEVEL_7_ALIGNMENT} {...props} reload={reload}/>
            <Level6Depth title={ANALYSIS_TYPES_VERBOSE.LEVEL_6_DEPTH} {...props} reload={reload}/>
            <Level6Breadth title={ANALYSIS_TYPES_VERBOSE.LEVEL_6_BREADTH} {...props} reload={reload}/>
            <Level6Enough title={ANALYSIS_TYPES_VERBOSE.LEVEL_6_ENOUGH} {...props} reload={reload}/>
            <Level5Analysis title={ANALYSIS_TYPES_VERBOSE.LEVEL_5_ANALYSIS} {...props} reload={reload}/>
        </TabContainer>
        <Box mt={2} align={'center'} display={'flex'} justifyContent={"center"} gridColumnGap={10}>
            <Button variant='contained' color={!statusGreaterThanModule ? "primary" : "secondary"} onClick={() => generateReport()}>Next: Report</Button>
            <Button variant='contained' className={classes.excel_button} onClick={downloadExcel}>Download Excel</Button>
        </Box>
    </>
}

export default AnalysisModule;