import {invokeApi} from '../bl_libs/invokeApi';
import {LOCAL_STORAGE_KEYS} from "../utils/constants";

//TODO: Append 'service' tag to each service e.g. getApplicationsService

export const getAppsService = () => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/app/list',
        method : 'GET',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };

    return invokeApi(requestObj);
}

export const getAppService = (application_id) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/app/get',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id,
    };

    return invokeApi(requestObj);
}

export const getAppDetailService = (application_id) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/app/details/get',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id
    };

    return invokeApi(requestObj);
}

export const getAppLogsService = (application_id) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/app/logs/get',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id,
    };

    return invokeApi(requestObj);
}

export const updateExcludeNonDQCService = (application_id, is_exclusive_non_dqc) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/app/exclude_non_dqc/update',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id,
        is_exclusive_non_dqc
    };

    return invokeApi(requestObj);
}

export const updateAppCompanyService = (application_id, form_data) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/app/company/update',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id,
        ...form_data
    };

    return invokeApi(requestObj);
}

export const updateAppProgramService = (application_id, form_data) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/app/program/update',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id,
        ...form_data
    };

    return invokeApi(requestObj);
}

export const updateAppStatusService = (application_id, app_status, send_email = true) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/app/status/update',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id,
        app_status,
        send_email
    };

    return invokeApi(requestObj);
}

export const updateAppAmountService = (application_id, amount) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/app/amount/update',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id,
        amount
    };

    return invokeApi(requestObj);
}


export const getLearningMsgsService = (application_id, module_id) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/app/messages/list',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id,
        module_id
    };

    return invokeApi(requestObj);
}

export const getMLMappingsService = (application_id) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/app/mlmappings/list',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id
    };
    return invokeApi(requestObj);
}

export const postCleaningService = (application_id, landing_time) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/app/cleaning/post',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id,
        landing_time
    };

    return invokeApi(requestObj);
}

export const postLearningMsgsService = (application_id, messages) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/app/messages/post',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id,
        messages
    };

    return invokeApi(requestObj);
}

export const postLmsCSV_Service = (application_id, module_id, csv_data) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/app/lms_csv_data/post',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id,
        module_id,
        csv_data
    };

    return invokeApi(requestObj);
}

export const getMsgCommentsService = (application_id, message_id) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/app/message/comments/get',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id,
        message_id,
    };

    return invokeApi(requestObj);
}

export const postMsgCommentService = (application_id, message_id, comment) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/app/message/comment/post',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id,
        message_id,
        comment
    };

    return invokeApi(requestObj);
}

export const updateMsgCommentService = (application_id, message_id, comment_index, deletion, comment) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/app/message/comment/update',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id,
        message_id,
        comment_index,
        deletion,
        comment
    };

    return invokeApi(requestObj);
}

export const getMsgLogsService = (application_id, message_id,flg) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/app/message/logs/get',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    if(!flg)
        flg=false;

    requestObj['postData'] = {
        application_id,
        message_id,
        flg,
    };

    return invokeApi(requestObj);
}

export const getAnalysisService = (application_id, analysis_type) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/app/analysis/get',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id,
        analysis_type
    };

    return invokeApi(requestObj);
}

export const getAnalysisModService = (application_id, analysis_type, module_id) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/app/analysis_module/get',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id,
        analysis_type,
        module_id
    };

    return invokeApi(requestObj);
}


export const postAnalysisService = (application_id, analysis_type, analysis, module_id) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/app/analysis/post',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id,
        analysis_type,
        analysis,
        module_id
    };

    return invokeApi(requestObj);
}

export const getLevelMapsService = () => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/level_mappings',
        method : 'GET',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };

    return invokeApi(requestObj);
}

export const getOverviewDataService = () => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/overview/get',
        method : 'GET',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };

    return invokeApi(requestObj);
}

export const addModuleData = (application_id, data) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/app/modules/add',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id,
        ...data
    };

    return invokeApi(requestObj);
}

export const updateModuleData = (module_db_id, data) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/app/modules/update',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        module_db_id,
        ...data
    };

    return invokeApi(requestObj);
}

export const deleteModuleData = (application_id, module_db_id) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/app/modules/delete',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id,
        module_db_id,
    };

    return invokeApi(requestObj);
}

export const listModsService = (application_id) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/app/modules/list',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id
    };

    return invokeApi(requestObj);
}

export const listAppModsService = (application_id) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/app/modules/list',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id
    };

    return invokeApi(requestObj);
}

export const submitModuleData = (application_id,configs) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/app/modules/submit',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id,
        configs
    };

    return invokeApi(requestObj);
}

export const deleteContent = (file_path) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/content/remove',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        file_path
    };

    return invokeApi(requestObj);
}

export const updateProgramContent = (application_id, module_db_id, file_path) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/content/update',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        application_id,
        module_db_id,
        file_path
    };

    return invokeApi(requestObj);
}

export const uploadMedia = (media, progressCb) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/content/upload',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`,
            'content-type': 'multipart/form-data'
        },
        onUploadProgress: progressCb
    };
    requestObj['postData'] = media;

    return invokeApi(requestObj);
}

export const getSampleInstruments = (competency_list) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/sample_instruments/get',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`,
        },
    };
    requestObj['postData'] = {
        competency_list
    };

    return invokeApi(requestObj);
}

export const getCertifyAppsService = () => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/certify/list/apps',
        method : 'GET',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };

    return invokeApi(requestObj);
}

export const approveCertifyAppsService = (application_id, form_data) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/certify/assessment/approve',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };

    requestObj['postData'] = {
        application_id,
        ...form_data
    };

    return invokeApi(requestObj);
}

export const listAssessInstruments = (application_id) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/certify/list/assess_instruments',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };

    requestObj['postData'] = {
        application_id,
    };

    return invokeApi(requestObj);
}

export const listAssessTotalInstruments = (application_id) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/certify/list/assess_total_instruments',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };

    requestObj['postData'] = {
        application_id,
    };

    return invokeApi(requestObj);
}

export const updateAssessInstruments = (application_id, {operation, instrument}) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/certify/update/assess_instrument',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };

    requestObj['postData'] = {
        application_id,
        operation,
        instrument,
    };

    return invokeApi(requestObj);
}

export const listAssessModules = (application_id) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/certify/list/assess_modules',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };

    requestObj['postData'] = {
        application_id,
    };

    return invokeApi(requestObj);
}

export const uploadMediaFile = (media, progressCb) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/storage/put',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`,
            'content-type': 'multipart/form-data'
        },
        onUploadProgress: progressCb
    };
    requestObj['postData'] = media;

    return invokeApi(requestObj);
}

export const updateAssessModules = (application_id, module) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/certify/update/assess_module',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };

    requestObj['postData'] = {
        application_id,
        module
    };

    return invokeApi(requestObj);
}

export const listModuleDefinition = (application_id) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/certify/list/module_definition',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };

    requestObj['postData'] = {
        application_id,
    };

    return invokeApi(requestObj);
}

export const getLevel3Service = () => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/meta_data/level3/list',
        method : 'GET',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };

    return invokeApi(requestObj);
}

export const getLevel6Service = () => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/meta_data/level6/list',
        method : 'GET',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };

    return invokeApi(requestObj);
}

export const getLmsListWithHistory = (application_id) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/dqseal_backoffice/app/lms/list_with_history',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };

    requestObj['postData'] = {
        application_id,
    };

    return invokeApi(requestObj);
}