import React, {useCallback, useEffect, useMemo, useRef, useState, memo} from 'react';
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from "@material-ui/core";
import {APP_STATUS, LM_TYPE} from "../../../utils/constants";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import {
    getLearningMsgsService, listModsService, postCleaningService,
    postLearningMsgsService,
} from "../../../services/app.service";
import {alphaNumericSort, toastSuccess} from "../../../utils/utils";
import TextField from "@material-ui/core/TextField";
import MemoLearningMessageTable from "../components/CleanLMTable.comp";
import CommentDialog from "../components/MsgCommentDialog.comp";
import LogDialog from "../components/MsgLogDialog.comp";
import {useAuth} from "../../../context/auth";
import EditableChips from "../components/EditableChips.comp";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4),
    },
    paper: {
        position: 'absolute',
        width: '80%',
        maxHeight: '80%',
        overflowY: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const LABEL_EDIT_MODES = {
    EDIT_MESSAGE: 0,
    EDIT_RESPONSE: 1,
    ADD_RESPONSE: 2
}

function CleaningModule(props) {

    let {application_id, app_status, updateStatus, updateStep, forwardStep, setLoading} = props;

    const landingTime = useRef(new Date(Date.now()));
    const [modules, setModules] = useState([]);
    const [selectedModule, setSelectedModule] = useState(undefined);
    const learnMsgsRef = useRef([]);
    const [learnMsgs, setLearnMsgs] = useState([]);
    const dialogItemIndex = useRef(undefined);
    const [dialogItemChanges, setDialogItemChanges] = useState({});
    const dialogItem = dialogItemIndex.current !== undefined ?
        {...learnMsgsRef.current[dialogItemIndex.current], ...dialogItemChanges}
    : undefined;

    const [checkedIndices, setCheckedIndices] = useState({});

    const [editLabelDialogOpen, setEditLabelDialogOpen] = useState(false);
    const [labelEditMode, setLabelEditMode]  = useState(LABEL_EDIT_MODES.EDIT_MESSAGE)

    const [cmtDialogOpen, setCmtDialogOpen] = useState(false);
    const [logDialogOpen, setLogDialogOpen] = useState(false);

    const [enableReload, setEnableReload] = useState(false);

    const {authUser} = useAuth();

    const onEnableReload = (checked) => {
        setEnableReload(checked);
        console.log(checked);
    }

    const handleLabelEditDialogOpen = (edit_mode, index) => {
        if(dialogItemIndex.current !== index) { //last opened index is not same
            setDialogItemChanges({});
        }
        dialogItemIndex.current = index;
        setLabelEditMode(edit_mode);
        setEditLabelDialogOpen(true);
    }
    const handleLabelEditDialogClose = () => {
        setEditLabelDialogOpen(false);
    }
    const handleLabelEditDialogSubmit = () => {
        const index = dialogItemIndex.current;
        updateMessage(index, dialogItemChanges);
        setDialogItemChanges({});
        handleLabelEditDialogClose();
    }
    const handleMsgEdit = (e) => {
        const edited_message = e.target.value;
        setDialogItemChanges({...dialogItemChanges, message: edited_message})
    };
    const handleResponseEdit = (index, response) => {
        if(dialogItem) {
            const new_responses = [...dialogItem.responses];
            new_responses.splice(index, 1, response);
            setDialogItemChanges({...dialogItemChanges, responses: new_responses})
        }
    }
    const handleResponseAdd = (response) => {
        if(dialogItemIndex) {
            const new_responses = [...dialogItem.responses];
            new_responses.push(response);
            setDialogItemChanges({...dialogItemChanges, responses: new_responses})
        }
    }
    const handleResponseDelete = (response_index) => {
        if(dialogItemIndex) {
            const new_responses = [...dialogItem.responses];
            new_responses.splice(response_index, 1);
            setDialogItemChanges({...dialogItemChanges, responses: new_responses})
        }
    }

    const handleCmtDialogOpen = (index) => {
        dialogItemIndex.current = index;
        setCmtDialogOpen(true);
    }

    const handleCmtDialogClose = (index, updatedComments) => {
        if(updatedComments) {
            updateMessage(index, {comments: updatedComments}, false);
        }
        dialogItemIndex.current = undefined;
        setCmtDialogOpen(false);
    }

    const handleLogDialogOpen = (index) => {
        dialogItemIndex.current = index;
        setLogDialogOpen(true);
    }

    const handleLogDialogClose = () => {
        dialogItemIndex.current = undefined;
        setLogDialogOpen(false);
    }

    const onChangeModule = async (module_id) => {
        setSelectedModule(module_id);
        fetchLearningMessages(module_id).then(() =>
            console.log(`fetch completed -> learning messages`))
    }

    const statusGreaterThanModule = app_status >= APP_STATUS.PENDING_REVIEW_MAPPING;
    const statusInProgress = app_status === APP_STATUS.IN_PROGRESS_LEVEL_MAPPING;

    async function fetchModules() {
        let module_id = undefined;
        setLoading(true);
        try {
            const result = await listModsService(application_id);
            if(!result.error) {
                const {all_mods} = result.data;
                setModules(all_mods);
                if(all_mods.length > 1) {
                    const sorted_mods = all_mods.sort((a, b) => a.order_no - b.order_no);
                    module_id = sorted_mods[0].module_id;
                    setSelectedModule(module_id);
                }
            }
        }
        catch (e) {
            console.error(e);
        }
        setLoading(false);
        return module_id;
    }

    async function fetchLearningMessages(module_id) {
        setLoading(true);
        try {
            const result = await getLearningMsgsService(application_id, module_id);
            if(!result.error) {
                const {messages = []} = result.data;
                learnMsgsRef.current = messages;
                updateLearningMessageState();
            }
        }
        catch (e) {
            console.error(e);
        }
        setLoading(false);
    }

    async function saveMessages(messages) {
        try {
            const result = await postLearningMsgsService(application_id, messages);
            if(!result.error && result.data.success) {
                console.log("Messages saved successfully");
            }
        }
        catch (e) {
            console.error(e);
            //already toasted the error
        }
    }

    async function submit() {
        setLoading(true);
        try {
            const result = await postCleaningService(application_id, landingTime.current);
            if(!result.error && result.data.success) {
                toastSuccess('Success', 'Learning Messages Submitted')
                const {status} = result.data;
                updateStep(status, true);
            }
        }
        catch (e) {
            console.error(e);
            // already toasted the error
        }
        setLoading(false);
    }

    useEffect(() => {
        if(application_id !== undefined) {
            fetchModules().then((module_id) => {
                console.log(`fetch completed -> modules`)
                fetchLearningMessages(module_id).then(() =>
                    console.log(`fetch completed -> learning messages`))
            })
        }
    }, [])

    function removeSelection() {
        for(const index in checkedIndices) {
            removeMessage(index);
        }
    }

    function mergeSelection() {
        const selectedIndices = Object.entries(checkedIndices).map(([msgIndex, {msgID, selectedAt}]) => ({msgIndex, msgID, selectedAt}));
        //sorting on message_id
        selectedIndices.sort(alphaNumericSort("selectedAt", true, false));
        //merging
        let mergedMessage = "", mergedType = LM_TYPE.LESSON;
        const mergedResponses = [];
        for(const index in selectedIndices) {
            let prefix;
            const {msgIndex} = selectedIndices[index];
            const {message, type, responses} = learnMsgsRef.current[msgIndex];
            if(index === "0") { // execute for all except first
                mergedType = type;
                prefix = "";
            } else {
                prefix = " ";
                removeMessage(msgIndex);
            }
            mergedMessage += prefix + message;
            if(type === LM_TYPE.INSTRUMENT) {
                mergedType = LM_TYPE.INSTRUMENT;
                mergedResponses.push(...responses);
            }
        }
        const updatedParams = {message: mergedMessage, responses: mergedResponses, type: mergedType};
        updateMessage(selectedIndices[0].msgIndex, updatedParams);
        setCheckedIndices({});
    }

    function selectMessage(index, selection) {
        setCheckedIndices((prevState) => {
            const oldSelection = {...prevState};
            if(selection)
                oldSelection[index] = {msgID: learnMsgsRef.current[index].message_id, selectedAt: Date.now()}
            else
                delete oldSelection[index];
            return oldSelection;
        });
    }

    function updateMessage(index, params, save = true, updateLmState = true, showToast = true) {
        const currentTime = new Date(Date.now()).toISOString();
        const localParams = {updatedAt: currentTime, updatedBy: authUser.email};
        const updatedParams = {reload_level: enableReload, ...params};
        const messagesCopy = [...learnMsgsRef.current];
        const new_message = {...messagesCopy[index], ...localParams, ...updatedParams};
        messagesCopy.splice(index, 1, new_message);
        learnMsgsRef.current = messagesCopy;
        if(save) {
            const {message_id} = new_message;
            saveMessages([{message_id, ...updatedParams}]).then((r) => `Message ${message_id}[${index}] saving`);
            if(showToast) toastSuccess('Success', 'Message updated')
        }
        if (updateLmState) {
            updateLearningMessageState();
        }
    }

    function createMessage(index, updatedParams = {}, save = true, updateLmState = true, showToast = true) {
        const currentTime = new Date(Date.now()).toISOString();
        const messagesCopy = [...learnMsgsRef.current];
        const original = learnMsgsRef.current[index];
        const [original_index, entry_time, random_num] = original.message_id.split('-');
        const message_id = original_index + '-' + Date.now() + '-' + Math.random().toString().split('.')[1]
        const localParams = {
            message_id, comments: [], responses: [...original.responses], reload_level: enableReload,
            is_deleted: false, updatedAt: currentTime, createdAt: currentTime, updatedBy: authUser.email
        }
        const message = {...original, ...localParams, ...updatedParams};
        messagesCopy.push(message);
        learnMsgsRef.current = messagesCopy;
        if(save) {
            saveMessages([message]).then((r) => `Message ${message_id}[${index}] saving`);
            if(showToast) toastSuccess('Success', 'Message created');
        }
        if(updateLmState) updateLearningMessageState();
    }

    function removeMessage(index, save = true, updatedLmState = true, showToast = true) {
        const updatedParams = {reload_level: false, is_deleted: true};
        selectMessage(index, false);
        updateMessage(index, updatedParams, save, updatedLmState, false);
        if(showToast) toastSuccess('Success', 'Message deleted')
    }

    function updateLearningMessageState() {
        setLearnMsgs(learnMsgsRef.current);
    }

    //memos
    const memoLearnMsg = useMemo(() => learnMsgs, [learnMsgs])
    const memoModules = useMemo(() => modules, [modules])
    const memoCheckedIndices = useMemo(() => checkedIndices, [checkedIndices]);
    const memoSelectCallback = useCallback(selectMessage, [checkedIndices]);
    const memoMergeSelectedCallback = useCallback(mergeSelection, [checkedIndices]);
    const memoRemoveSelectedCallback = useCallback(removeSelection, [checkedIndices]);
    const memoUpdateCallback = useCallback(updateMessage, []);
    const memoRemoveCallback = useCallback(removeMessage, [checkedIndices]);
    const memoCreateCallback = useCallback(createMessage, [checkedIndices]);
    const memoLabelTriggerCallback = useCallback(handleLabelEditDialogOpen, []);
    const memoOpenCommentsCallback = useCallback(handleCmtDialogOpen, []);
    const memoOpenLogsCallback = useCallback(handleLogDialogOpen, []);
    const memoChangeModuleCallback = useCallback(onChangeModule, []);
    const memoEnableReloadCallback = useCallback(onEnableReload, []);

    const editModes = {
        [LABEL_EDIT_MODES.EDIT_MESSAGE]: {
            title: "Edit Learning Message",
            placeholder: "Your message here",
            primary_btn: "Edit",
            secondary_btn: "Close",
            callback: handleLabelEditDialogSubmit
        }
    }

    return (
        <>
            {
                learnMsgs ?
                    <MemoLearningMessageTable
                        modules={memoModules}
                        messages={memoLearnMsg}
                        selectedModule={selectedModule}
                        checkedIndices={memoCheckedIndices}
                        selectMessage={memoSelectCallback}
                        mergeSelected={memoMergeSelectedCallback}
                        removeSelected={memoRemoveSelectedCallback}
                        updateMessage={memoUpdateCallback}
                        removeMessage={memoRemoveCallback}
                        createMessage={memoCreateCallback}
                        editLabelTrigger={memoLabelTriggerCallback}
                        editLabelModes={LABEL_EDIT_MODES}
                        openComments={memoOpenCommentsCallback}
                        openLogs={memoOpenLogsCallback}
                        changeModule={memoChangeModuleCallback}
                        autoEnableReload={memoEnableReloadCallback}
                    />
                :
                    null
            }
            <Box mt={2} align="center" display="flex" justifyContent="center" gridColumnGap={10}>
                {(!statusInProgress && !statusGreaterThanModule) || (statusInProgress || statusGreaterThanModule) ? (
                    <Button
                        variant="contained"
                        color={!statusInProgress && !statusGreaterThanModule ? "primary" : "secondary"}
                        onClick={() => {
                            if (!statusInProgress && !statusGreaterThanModule) {
                                submit().then(r => console.log("Cleaning Done"));
                            } else {
                                forwardStep();
                            }
                        }}
                    >
                        Next: Codification
                    </Button>
                ) : null}
            </Box>
            <Dialog id={"edit-label-dialog"} open={editLabelDialogOpen} onClose={handleLabelEditDialogClose} fullWidth={true} maxWidth={'md'}>
                <DialogTitle id="edit-label-dialog-title">{editModes[labelEditMode].title}</DialogTitle>
                <DialogContent>
                    {
                        dialogItem &&
                        <TextField
                            defaultValue={dialogItem.message}
                            onChange={handleMsgEdit}
                            variant="outlined"
                            placeholder={editModes[labelEditMode].placeholder}
                            fullWidth
                            multiline
                            minRows={5}
                        />
                    }
                    {
                        dialogItem && dialogItem.type === LM_TYPE.INSTRUMENT &&
                        <>
                            <br/>
                            <br/>
                            <EditableChips
                                chips={dialogItem.responses}
                                addChipTitle={'New Response'}
                                addChipHandler={handleResponseAdd}
                                removeChipHandler={handleResponseDelete}
                                editChipHandler={handleResponseEdit}
                            />
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleLabelEditDialogClose} color="primary">
                        {editModes[labelEditMode].secondary_btn}
                    </Button>
                    <Button onClick={editModes[labelEditMode].callback} color="primary">
                        {editModes[labelEditMode].primary_btn}
                    </Button>
                </DialogActions>
            </Dialog>
            <CommentDialog
                applicationID={application_id}
                messages={learnMsgsRef.current} messageIndex={dialogItemIndex.current}
                open={cmtDialogOpen} onClose={handleCmtDialogClose}
            />
            <LogDialog
                applicationID={application_id}
                messages={learnMsgsRef.current} messageIndex={dialogItemIndex.current}
                open={logDialogOpen} onClose={handleLogDialogClose}
            />
        </>
    );
}

export default CleaningModule;