import React, {useEffect, useState, memo} from "react";
import {
    Badge,
    Button,
    TableCell,
    TableRow,
    Tooltip,
    TextField,
    Grid,
    IconButton,
    Typography,
    Checkbox, Box, Chip, InputBase
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ChatIcon from '@material-ui/icons/Chat';
import HistoryIcon from '@material-ui/icons/History';
import AddIcon from '@material-ui/icons/Add';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import {Autocomplete} from "@material-ui/lab";
import {LM_TYPE} from "../../../utils/constants";
import {getMapFromObjectListByKey, toastSuccess} from "../../../utils/utils";

const useStyles = makeStyles((theme) => ({
    clickable_text: {
        borderBottom: "1px dotted #000",
        cursor: "pointer"
    },
    message_tf: {
        minWidth: '500px'
    },
    read_only_tf: {
        minWidth: '60px'
    },
    modified_row: {
        borderLeft: '5px solid orange',
    },
    deleted_row: {
        borderLeft: '5px solid red',
        backgroundColor: '#ffcccc'
    }
}))

// export const typeOptions = [
//     {title: 'Lesson', code: LM_TYPE.LESSON},
//     {title: 'Instrument', code: LM_TYPE.INSTRUMENT},
//     {title: 'Objective', code: LM_TYPE.OBJECTIVE},
//     {title: 'Styles (Heading)', code: LM_TYPE.HEADING},
//     {title: 'Styles (Title)', code: LM_TYPE.TITLE},
//     {title: 'Styles (Subtitle)', code: LM_TYPE.SUBTITLE},
// ]
export const typeOptions = [
    {title: 'LESSON', code: LM_TYPE.LESSON},
    {title: 'INSTRUMENT', code: LM_TYPE.INSTRUMENT},
    {title: 'OBJECTIVE', code: LM_TYPE.OBJECTIVE},
    {title: 'HEADING', code: LM_TYPE.HEADING},
    {title: 'TITLE', code: LM_TYPE.TITLE},
    {title: 'SUBTITLE', code: LM_TYPE.SUBTITLE},
]

const typeOptionsObj = getMapFromObjectListByKey(typeOptions, "code");
const typeOptionsReverseObj = getMapFromObjectListByKey(typeOptions, "title");

function SimpleDropdown(props) {

    const {id, value, options, onChange} = props;

    return <Autocomplete
        id={id}
        value={value}
        options={options}
        disableClearable
        onChange={(e, newValue) => onChange(newValue)}
        style={{ width: 150 }}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
    />
}

const MemoLearningMessageRow = memo(LearningMessageRow);
export default MemoLearningMessageRow;
function LearningMessageRow(props) {

    const classes = useStyles();

    const {message_object: message, index, isChecked, selectMessage, updateMessage, removeMessage, createMessage,
        editLabelTrigger, editLabelModes, openComments, openLogs, is_deleted, enableReload} = props;

    const isModified = message.reload_level === true;

    function onDuplicateMessage() {
        createMessage(index, {}, true, true, false);
        toastSuccess('Success', 'Message duplicated');
    }

    function onRemoveMessage() {
        removeMessage(index);
    }

    function onSelectMessage(e) {
        selectMessage(index, e.target.checked);
    }

    function onSplitMessage() {
        updateMessage(index, {type: LM_TYPE.LESSON, responses: []}, true, true, false);
        for(const resp of message.responses) {
            createMessage(index, {type: LM_TYPE.LESSON, message: resp, responses: []}, true, true, false);
        }
        toastSuccess('Success', 'Message split');
    }

    function onChangeType(type) {
        const updatedParams = {};
        const updatedType = typeOptionsReverseObj[type].code;
        updatedParams.type = updatedType;
        if(message.type === LM_TYPE.INSTRUMENT && updatedType === LM_TYPE.LESSON) {
            updatedParams.responses = [];
            updatedParams.message = message.message.concat("\n", message.responses.join("\n"));
        }
        updatedParams.reload_level = enableReload;
        updateMessage(index, updatedParams)
    }

    function onToggleReloadLevel() {
        updateMessage(index, {reload_level: !message.reload_level})
    }

    return <TableRow className={is_deleted ? classes.deleted_row : isModified ? classes.modified_row : undefined}>
        <TableCell>
            <Checkbox name={`Checkbox-${index}`} checked={isChecked} onChange={onSelectMessage} color="primary"/>
            {message.message_id}
        </TableCell>
        <TableCell className={classes.message_tf}>
            <span className={classes.clickable_text} onClick={() => editLabelTrigger(editLabelModes.EDIT_MESSAGE, index)}>
                {
                    message.message === "" ?
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    : message.message.split('\n').map((text, text_idx, text_arr) =>
                            <>
                                {text_idx !== 0 && <br/>}
                                <span>{text}</span>
                            </>
                    )
                }
            </span>
            {
                message.type === LM_TYPE.INSTRUMENT ?
                    <>
                        <br/>
                        <br/>
                        {
                            message.responses.map((resp) =>
                                <Chip
                                    style={{marginRight: 5, marginTop: 5}}
                                    size="small"
                                    label={resp}
                                    onClick={() => editLabelTrigger(editLabelModes.EDIT_MESSAGE, index)}
                                    clickable={true}
                                />
                            )
                        }
                    </>
                    : null
            }
        </TableCell>
        <TableCell>
            <SimpleDropdown
                id={"type_dropdown"}
                options={typeOptions.map((o) => o.title)}
                value={typeOptionsObj[message.type].title}
                onChange={onChangeType}
            />
        </TableCell>
        <TableCell>
            <Box display={'flex'} flexDirection={'row'}>
                <Tooltip title={'Duplicate'}>
                    <IconButton onClick={onDuplicateMessage}>
                        <FileCopyIcon color={"primary"}/>
                    </IconButton>
                </Tooltip>
                {
                    message.type === LM_TYPE.INSTRUMENT ? //repetitive implementation to avoid bugs
                        <Tooltip title={'Split'}>
                            <IconButton onClick={onSplitMessage}>
                                <CallSplitIcon htmlColor={"green"}/>
                            </IconButton>
                        </Tooltip>
                    :
                        <IconButton disabled={true}>
                            <CallSplitIcon htmlColor={"lightgray"}/>
                        </IconButton>
                }
                <Tooltip title={'Delete'}>
                    <IconButton onClick={onRemoveMessage}>
                        <DeleteIcon htmlColor={"darkred"}/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Toggle reload level"}>
                    <IconButton onClick={onToggleReloadLevel}>
                        <AutorenewIcon htmlColor={message.reload_level ? "orange" : "darkgrey"}/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Comments'}>
                    <IconButton onClick={() => openComments(index)}>
                        <Badge color="primary" badgeContent={message.comments.length}>
                            <ChatIcon color={"secondary"}/>
                        </Badge>
                    </IconButton>
                </Tooltip>
                <Tooltip title={'History'}>
                    <IconButton onClick={() => openLogs(index)}>
                        <HistoryIcon color={"secondary"}/>
                    </IconButton>
                </Tooltip>
            </Box>
        </TableCell>
    </TableRow>
}