import React, {memo, useMemo, useState} from "react";
import {
    Box, Button, Checkbox, FormControlLabel, Paper, TextField,
    Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, MenuItem,
} from "@material-ui/core";
import LearningMessageRow from "./CleanLMRow.comp";
import EnhancedTableCell, {getComparator} from "../../../components/EnhancedTableCell/EnhancedTableCell.comp";
import TableSearchBar from "../../../components/SearchBar/TableSearchBar.comp";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    red_checkbox: {
        '&.Mui-checked': {
            color: "darkred"
        }
    },
    select_box: {
        zIndex: 1,
        position: "sticky",
        top: "10%",
    }
}))

const headers = [
    { id: "message_id", label: "ID", alignment: "center", sort: true },
    { id: "message", label: "Learning Message", alignment: "center", sort: true },
    { id: "type", label: "Type", alignment: "center", sort: true },
]

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function getFieldValue(field, value) {
    if(value === null || value === undefined) return "";
    return value;
}

const MemoLearningMessageTable = memo(LearningMessageTable);
function LearningMessageTable(props) {

    const classes = useStyles();

    const {
        modules, selectedModule, messages: rows, checkedIndices, selectMessage, mergeSelected, removeSelected,
        updateMessage, removeMessage, createMessage, editLabelTrigger, editLabelModes, openComments, openLogs, changeModule,
        autoEnableReload
    } = props;

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("message_id");
    const [searchText, setSearchText] = useState('');
    const [showDeleted, setShowDeleted] = useState(false);
    const [enableReload, setEnableReload] = useState(false);


    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const handleEnableReload = (checked) => {
        setEnableReload(checked);
        autoEnableReload(checked);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        const sortDirection = isAsc ? "desc" : "asc"
        setOrder(sortDirection);
        setOrderBy(property);
    };

    const handleSearchChange = (text) => {
        setPage(0);
        setSearchText(text);
    }

    /* Search Related Func */
    const searchRegex = new RegExp(escapeRegExp(searchText), 'i');
    const search_filter_function = (row) => {
        const headers_keys = headers.map((h) => h.id);
        return headers_keys.some((field) => {
            const value = getFieldValue(field, row[field]);
            return searchRegex.test(value.toString());
        });
    }
    /* End */

    const filteredRows = rows
        .map((row, index) => ({...row, index, isChecked: !!checkedIndices[index]})) //adding index
        .filter((row) => showDeleted || !row.is_deleted)
        .filter(search_filter_function) //search filter

    const slicedRows = filteredRows
        .sort(getComparator(order, orderBy)) //sorting
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) //pagination

    const rowsSelected = Object.keys(checkedIndices).length;

    return <>
        <Box display={'flex'} mb={2}>
            {
                selectedModule &&
                <TextField
                    id="module-select"
                    select
                    variant="outlined"
                    label="Select Module"
                    value={selectedModule}
                    onChange={(e) => changeModule(e.target.value)}
                >
                    {[...modules]
                        .sort((a, b) => a.order_no - b.order_no)
                        .map((option) => (
                        <MenuItem key={option.module_id} value={option.module_id}>
                            {option.module_name}
                        </MenuItem>
                    ))}
                </TextField>
            }
            <Box ml={'auto'}>
                <TableSearchBar searchValue={searchText} onChange={handleSearchChange}/>
            </Box>
        </Box>
        {
            rowsSelected ?
            <Box className={classes.select_box} display={'flex'} flexDirection={'column'} alignItems={'center'} gridRowGap={10} mb={2}>
                <Box display={'flex'} justifyContent={'center'} gridColumnGap={5}>
                    <Button color="primary" variant="contained" disabled={rowsSelected <= 1} onClick={() => mergeSelected()}>
                        Merge Selected
                    </Button>
                    <Button color="secondary" variant="contained" onClick={() => removeSelected()}>
                        Delete Selected
                    </Button>
                    <br/>
                </Box>
                {
                    rowsSelected <= 1 ? `${rowsSelected} Row Selected`
                    : `${rowsSelected} Rows Selected`
                }
            </Box> : null
        }
        <Box textAlign={'right'}>
            <FormControlLabel
                control={<Checkbox className={classes.red_checkbox} name={`Checkbox-Show-Deleted`} checked={showDeleted} onChange={(e) => setShowDeleted(e.target.checked)}/>}
                label={'Show Deleted'}
            />
            <FormControlLabel
                control={<Checkbox className={classes.red_checkbox} name={`Checkbox-Enable-Reload`} checked={enableReload} onChange={(e) => handleEnableReload(e.target.checked)}/>}
                label={'Auto Enable Reload'}
            />
        </Box>
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {
                            headers.map((item) =>
                                <EnhancedTableCell
                                    id={item.id}
                                    alignment={item.alignment}
                                    sort={item.sort}
                                    orderBy={orderBy}
                                    order={order}
                                    onRequestSort={handleRequestSort}
                                >
                                    {item.label}
                                </EnhancedTableCell>
                            )
                        }
                        <TableCell align={'center'}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        slicedRows
                            .map((msg) =>
                                <LearningMessageRow
                                    key={msg.message_id}
                                    message_object={msg}
                                    selectMessage={selectMessage}
                                    updateMessage={updateMessage}
                                    removeMessage={removeMessage}
                                    createMessage={createMessage}
                                    editLabelTrigger={editLabelTrigger}
                                    editLabelModes={editLabelModes}
                                    openComments={openComments}
                                    openLogs={openLogs}
                                    enableReload={enableReload}
                                    {...msg}
                                />
                            )
                    }
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </>
}

export default MemoLearningMessageTable;